import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Space,
  Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { deleteKioskGroupService, getAllKioskGroupService } from 'common/services/kiosk';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export type KioskGroupDataTypes = {
  id: number;
  name: string;
  includeWebsite: boolean;
  displayOrder?: number;
  setupAt?: string;
  createdAt: string;
  updatedAt: string;
};

const KioskGroupManagement: React.FC<ActiveRoles> = ({
  roleCreate,
  roleDelete,
  roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Selectors */
  const {
    defaultPageSize,
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  /* Queries */
  const { data: kioskGroups, isLoading } = useQuery(
    ['getAllKioskGroup', keyword, currentPage, currentView],
    () => getAllKioskGroupService({
      keyword, page: currentPage, limit: currentView
    }),
  );

  const { mutate: deleteKioskGroupMutate, isLoading: deleteKioskGroupLoading } = useMutation(
    ['kiosk-group-delete'],
    async (ids: number[]) => deleteKioskGroupService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['getAllKioskGroup']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const handleDelete = useCallback((data: KioskGroupDataTypes[]) => {
    deleteKioskGroupMutate(data.map((ele) => ele.id));
  }, [deleteKioskGroupMutate]);

  /* Variables */
  const columns: ColumnsType<KioskGroupDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: KioskGroupDataTypes, b: KioskGroupDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: KioskGroupDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tiêu đề
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: KioskGroupDataTypes, b: KioskGroupDataTypes) => a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: KioskGroupDataTypes) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('kioskManagement.includeWebsite'),
      dataIndex: 'includeWebsite',
      key: 'includeWebsite',
      render: (_: string, data: KioskGroupDataTypes) => (
        <Typography.Text>
          {data.includeWebsite ? t('kioskManagement.yes') : t('kioskManagement.no')}
        </Typography.Text>
      ),
    },
    {
      title: t('kioskManagement.displayOrder'),
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      render: (_: string, data: KioskGroupDataTypes) => (
        <Typography.Text>
          {data.displayOrder}
        </Typography.Text>
      ),
    },
    {
      title: t('kioskManagement.setupAt'),
      dataIndex: 'setupAt',
      key: 'setupAt',
      render: (_: string, data: KioskGroupDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.setupAt)}
        </Typography.Text>
      ),
    },
    // --- Ngày tạo
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: KioskGroupDataTypes, b: KioskGroupDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: KioskGroupDataTypes, b: KioskGroupDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: KioskGroupDataTypes) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            disabled={!roleUpdate}
            onClick={() => navigate(`${ROUTE_PATHS.KIOSK_GROUP_DETAIL}?id=${_data.id}`)}
          />
          <Button
            icon={<DeleteOutlined />}
            disabled={!roleUpdate}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                title: t('message.confirmDeleteRecord'),
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                onOk: () => {
                  handleDelete([_data]);
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  const kioskGroupData = useMemo(() => (kioskGroups?.data?.map((val) => ({
    id: val.kioskGroupData.id,
    name: val.kioskGroupData.name,
    includeWebsite: val.kioskGroupData.includeWebsite,
    displayOrder: val.kioskGroupData.displayOrder,
    setupAt: val.kioskGroupData.setupAt,
    createdAt: val.kioskGroupData.createdAt,
    updatedAt: val.kioskGroupData.updatedAt,
  })) || []), [kioskGroups]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.kioskGroup')}
        rightHeader={(
          <Button type="primary" disabled={!roleCreate} onClick={() => roleCreate && navigate(`${ROUTE_PATHS.KIOSK_GROUP_DETAIL}`)}>
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={handleDelete}
          handleSearch={setKeyword}
          isLoading={isLoading || deleteKioskGroupLoading}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'includeWebsite', 'displayOrder', 'setupAt', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: kioskGroupData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: kioskGroups?.meta.total || 1,
            noBaseCol: true,
          }}
        />
      </div>
    </>
  );
};

export default KioskGroupManagement;
