import { ExportOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker, Divider, Empty, Select, Spin, Typography,
  message
} from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import convertDataSet, { convertCounterDataSet } from './helper';

import ChartCustom from 'common/components/Chart';
import {
  getOldSurveyCountersService, getOldSurveyHeartRiskService,
  getOldSurveyKidneyRiskService,
  getSummariesCounterV2Service,
  getSummariesHeartRiskPeriodsV2Service,
  getSummariesHeartRiskV2Service,
  getSummariesKidneyRiskV2Service,
  getSummariesPeriodsV2Service,
  getSurveyCountersV2Service,
  getSurveyHeartRiskV2Service,
  getSurveyKidneyRiskV2Service
} from 'common/services/dashboard';
import mapModifiers, { formatDateYYYYMMDD } from 'common/utils/functions';

const { RangePicker } = DatePicker;

interface VerticalBarChartV2Props {
  title: string;
  typeSchema?: 'YNTCThan' | 'SLBN' | 'YNTCTimMach';
  height: number;
  isOlData?: boolean;
  legendCustomId?: string;
  isExport?: boolean;
  isExportPeriod?: boolean;
}

const dateFormat = 'YYYY-MM-DD';
const defaultDateValue = [moment(new Date()).subtract(1, 'months').format(dateFormat), moment(new Date()).format(dateFormat)];

const VerticalBarChartV2: React.FC<VerticalBarChartV2Props> = ({
  title,
  typeSchema,
  height,
  isOlData,
  legendCustomId,
  isExport,
  isExportPeriod
}) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<string[] | undefined>(undefined);
  const [dateRange, setDateRange] = useState(defaultDateValue);

  const { data, isFetching, isLoading } = useQuery(
    ['getSurveyChartService', dateRange, typeSchema, isOlData],
    () => {
      if (typeSchema === 'SLBN') {
        if (isOlData) {
          return getOldSurveyCountersService({
            from: dateRange[0],
            to: dateRange[1],
          });
        }
        return getSurveyCountersV2Service({
          from: dateRange[0],
          to: dateRange[1],
        });
      }
      if (typeSchema === 'YNTCThan') {
        if (isOlData) {
          return getOldSurveyKidneyRiskService({
            from: dateRange[0],
            to: dateRange[1],
          });
        }
        return getSurveyKidneyRiskV2Service({
          from: dateRange[0],
          to: dateRange[1],
        });
      }
      if (isOlData) {
        return getOldSurveyHeartRiskService({
          from: dateRange[0],
          to: dateRange[1],
        });
      }
      return getSurveyHeartRiskV2Service({
        from: dateRange[0],
        to: dateRange[1],
      });
    },
    { enabled: dateRange.length === 2 }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportChartService', dateRange, typeSchema, isOlData, isExport],
    () => {
      if (typeSchema === 'SLBN' && isExport) {
        return getSummariesCounterV2Service({
          from: dateRange[0],
          to: dateRange[1],
        });
      }
      if (typeSchema === 'YNTCThan' && isExport) {
        return getSummariesKidneyRiskV2Service({
          from: dateRange[0],
          to: dateRange[1],
        });
      }
      if (isExport) {
        return getSummariesHeartRiskV2Service({
          from: dateRange[0],
          to: dateRange[1],
        });
      }
      return new Promise((resolve) => resolve({ link: '' }));
    },
    {
      onSuccess(res: any) {
        if (res && res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  const { mutate: exportPeriodMutate, isLoading: exportPeriodLoading } = useMutation(
    ['exportPeriodChartService', dateRange, typeSchema, isExportPeriod],
    () => {
      if (typeSchema === 'YNTCTimMach' && isExportPeriod) {
        return getSummariesHeartRiskPeriodsV2Service({
          from: dateRange[0],
          to: dateRange[1],
        });
      }
      if (typeSchema === 'YNTCThan' && isExportPeriod) {
        return getSummariesPeriodsV2Service({
          from: dateRange[0],
          to: dateRange[1],
        });
      }
      return new Promise((resolve) => resolve({ link: '' }));
    },
    {
      onSuccess(res: any) {
        if (res && res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  const newData = useMemo(() => {
    if (typeSchema === 'YNTCThan') {
      return convertDataSet('YNTCThan', data, selectedFilter, isOlData);
    }
    if (typeSchema === 'SLBN') {
      return convertCounterDataSet(t('dashboard.totalLine'), data, selectedFilter, isOlData);
    }

    if (typeSchema === 'YNTCTimMach' && data) {
      return convertDataSet('YNTCTimMach', data, selectedFilter, isOlData);
    }

    return undefined;
  }, [typeSchema, data, t, selectedFilter, isOlData]);

  const kioskDropdown = useMemo(() => {
    const kiosk = data?.map((item) => (isOlData ? item.kiosk : item.kioskGroup));
    const uniqueKioskLabels = kiosk?.filter((c, index) => kiosk.indexOf(c) === index);
    return uniqueKioskLabels?.map((item) => ({
      label: item,
      value: item,
    }));
  }, [data, isOlData]);

  const options = useMemo(() => {
    if (typeSchema === 'YNTCThan') {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom' as const,
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 12
              }
            }
          },
          title: {
            display: true,
            text: title,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: t('dashboard.hospitalList'),
            },
          },
          y: {
            title: {
              display: true,
              text: t('dashboard.numberByEachHospital'),
            }
          },
        }
      };
    }
    if (typeSchema === 'YNTCTimMach') {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom' as const,
          },
          title: {
            display: true,
            text: title,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: t('dashboard.numberRequestByEachHospital'),
            }
          },
        }
      };
    }
    if (typeSchema === 'SLBN') {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom' as const,
            // onClick: (e: any, legendItem: any) => {
            //   const index = legendItem.datasetIndex;
            //   const ci = e.chart;

            //   ci.data.datasets.forEach((item: any, i: number) => {
            //     const meta = ci.getDatasetMeta(i);

            //     if (i === index) {
            //       meta.hidden = !meta.hidden;
            //     }
            //   });

            //   if (ci.data.datasets.length > 0 && ci.data.datasets[0].label === 'Tổng') {
            //     const cloneDataSets = [...ci.data.datasets];
            //     const metaNotHidden = cloneDataSets.filter((_, i: number) => {
            //       const meta = ci.getDatasetMeta(i);
            //       return !meta.hidden;
            //     });
            //     const needMeta = metaNotHidden.splice(1);
            //     ci.data.datasets[0].data = ci.data.datasets[0].data.map((
            //       _: any,
            //       indexData: number
            //     ) => needMeta.reduce((prev, current) => prev + current.data[indexData], 0));
            //   }

            //   ci.update();
            // },
          },
          title: {
            display: true,
            text: title,
          },
        },
        elements: {
          point: {
            radius: 0
          }
        }
      };
    }
    return undefined;
  }, [typeSchema, t, title]);

  return (
    <div className="p-home_chart">
      <div className="p-home_filterWrap">
        <div className="p-home_filter">
          <div className="p-home_filter-heading">
            <Typography.Title level={3}>{title}</Typography.Title>
          </div>
          <div className="p-home_filter-datePicker">
            {kioskDropdown && (
            <div className="p-home_filter_select">
              <Typography.Text>Lọc: </Typography.Text>
              <div className="p-home_filter_select-wrapper">
                <Select
                  className="u-mt-8 p-home_filter_select-sl"
                  size="middle"
                  placeholder="Tất cả"
                  onChange={setSelectedFilter}
                  value={selectedFilter}
                  mode="multiple"
                  allowClear
                >
                  {
                    kioskDropdown?.map((val, idx) => (
                      <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                        {val.label}
                      </Select.Option>
                    ))
                  }
                </Select>
              </div>
            </div>
          )}
            <RangePicker
              defaultValue={[moment(dateRange[0], dateFormat),
            moment(dateRange[1], dateFormat)]}
              onChange={(val) => {
              if (!val) return;
              const res = val.map((item) => (formatDateYYYYMMDD(item)));
              setDateRange(res);
            }}
            />
            {
            isExport && (
              <div className="u-ml-12">
                <Button
                  type="primary"
                  loading={isLoading || exportLoading}
                  onClick={() => exportMutate()}
                >
                  <ExportOutlined />
                  {t('system.export')}
                </Button>
              </div>
            )
          }
          </div>
        </div>
        {isExportPeriod && (
        <Button
          type="primary"
          loading={isLoading || exportPeriodLoading}
          onClick={() => exportPeriodMutate()}
        >
          <ExportOutlined />
          {t('system.exportPeriod')}
        </Button>
)}
      </div>
      <Divider type="horizontal" />
      <Spin size="large" spinning={isFetching || isLoading}>
        <div className={mapModifiers('p-home_chart_wrap', typeSchema === 'YNTCThan' ? 'yntc-than' : '')}>
          {newData
            ? (
              <ChartCustom
                type="bar"
                data={newData}
                options={options}
                height={height}
                legendCustomId={legendCustomId}
                typeSchema={typeSchema}
                isMultipleType={typeSchema === 'SLBN'}
                onlyTotal={typeSchema === 'SLBN'}
              />
            ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>

      </Spin>
    </div>
  );
};

export default React.memo(VerticalBarChartV2);
