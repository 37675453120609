import axiosInstance, { axiosInstanceV2 } from '../common/instance';

import {
  TopActivityLogsTypes,
  GaSummariesParamsTypes,
  GaSummariesTypes,
  GaPagesTrackingTypes,
  GaUsersTracking,
  QuickActionSummaryType,
  QuickActionSummaryParams,
  GaPageTrackingTypes,
  GaPageTrackingParamsTypes,
  GaLocationsTrackingTypes,
  DateParams,
  DeviceList,
  GaVisitorParamsTypes,
  GaVisitorTracking,
  SurveyChartResponseDataTypes,
} from './types';

const prefixPath = 'dashboard/';

export const getTopActivityLogsService = async (): Promise<
  TopActivityLogsTypes[]
> => {
  const res = await axiosInstance.get(`${prefixPath}top-activity-logs`);
  return res.data.data;
};
/**
 * ga Key
 * identifier, users, sessions, avgSessionDuration, pageviewsPerSession,
 * newUsers, bounceRate, adMatchedQuery
 */

const postGaSummariesService = async (
  params: GaSummariesParamsTypes
): Promise<GaSummariesTypes[]> => {
  /**
   * metrics: sessions, activeUsers
   * groupBy: date, yearMonth
   */
  const res = await axiosInstance.post(`${prefixPath}ga-summaries`, params);
  return res.data.data;
};

export const postGaPagesTrackingService = async (
  params: GaSummariesParamsTypes
): Promise<GaPagesTrackingTypes[]> => {
  /**
   * Metric: newUsers, engagedSessions, engagementRate,
   * bounceRate, itemViews, screenPageViewsPerSession, screenPageViews
   */
  const res = await axiosInstance.post(
    `${prefixPath}ga-pages-tracking`,
    params
  );
  return res.data.data;
};

export const postGaUsersTrackingService = async (
  params: GaSummariesParamsTypes
): Promise<GaUsersTracking[]> => {
  // region, country, deviceCategory, browser
  const res = await axiosInstance.post(
    `${prefixPath}ga-users-tracking`,
    params
  );
  return res.data.data;
};
export const getGaVisitorTrackingService = async (
  params: GaVisitorParamsTypes
): Promise<GaVisitorTracking[]> => {
  const res = await axiosInstance.get(`${prefixPath}ga-visitors-tracking`, { params });
  return res.data.data;
};

export const quickActionSummariesService = async (
  params: QuickActionSummaryParams
): Promise<QuickActionSummaryType[]> => {
  const res = await axiosInstance.get(
    `${prefixPath}${params.prefix}-summaries`
  );
  return res.data.data;
};

export const getGAPageTrackingList = async (
  params: GaPageTrackingParamsTypes
): Promise<GaPageTrackingTypes[]> => {
  const res = await axiosInstance.get(`${prefixPath}ga-pages-tracking`, { params });
  return res.data.data;
};

export const getGaLocationTracking = async (
  params: DateParams
): Promise<GaLocationsTrackingTypes[]> => {
  // region, country, deviceCategory, browser
  const res = await axiosInstance.get(`${prefixPath}ga-locations-tracking`, { params });
  return res.data.data;
};

export const getDeviceTrackingService = async (
  params: DateParams,
): Promise<DeviceList[]> => {
  const res = await axiosInstance.get(`${prefixPath}ga-devices-tracking`, { params });
  return res.data.data;
};

export const getOldSurveyHeartRiskService = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstance.get('old-data-reports/surveys/heart-risk', { params });
  return res.data.data;
};

export const getOldSurveyCountersService = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstance.get('old-data-reports/surveys/counters', { params });
  return res.data.data;
};

export const getOldSurveyKidneyRiskService = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstance.get('old-data-reports/surveys/kidney-risk', { params });
  return res.data.data;
};

export const getSurveyHeartRiskService = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstance.get('reports/survey-results/heart-risk', { params });
  return res.data.data;
};

export const getSurveyCountersService = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstance.get('reports/survey-results/counters', { params });
  return res.data.data;
};

export const getSurveyKidneyRiskService = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstance.get('reports/survey-results/kidney-risk', { params });
  return res.data.data;
};

export const getReportSummariesService = async (
  params: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstance.get('reports/summaries', { params });
  return res.data.data;
};

export const getReportEvaluationsService = async (
  params: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstance.get('reports/evaluations', { params });
  return res.data.data;
};

export const getReportSummaryGropupService = async (
  params: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstance.get('reports/summary-groups', { params });
  return res.data.data;
};

export const getReportSummaryEventService = async (
  params: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstance.get('reports/summary-events', { params });
  return res.data.data;
};

export const getReportSummaryTrackingQrsService = async (
  params: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstance.get('reports/summary-tracking-qrs', { params });
  return res.data.data;
};

export const getReportSummaryOnlyHaveQrsService = async (
  params: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstance.get('reports/summary-only-have-qrs', { params });
  return res.data.data;
};

// V2
export const getSurveyHeartRiskV2Service = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstanceV2.get('reports/survey-results/heart-risk', { params });
  return res.data.data;
};

export const getSurveyCountersV2Service = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstanceV2.get('reports/survey-results/counters', { params });
  return res.data.data;
};

export const getSurveyKidneyRiskV2Service = async (
  params: DateParams,
): Promise<SurveyChartResponseDataTypes[]> => {
  const res = await axiosInstanceV2.get('reports/survey-results/kidney-risk', { params });
  return res.data.data;
};

export const getSummariesHeartRiskV2Service = async (
  params?: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstanceV2.get('reports/heart-risk-summaries', { params });
  return res.data.data;
};

export const getSummariesKidneyRiskV2Service = async (
  params?: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstanceV2.get('reports/kidney-risk-summaries', { params });
  return res.data.data;
};

export const getSummariesCounterV2Service = async (
  params?: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstanceV2.get('reports/counter-summaries', { params });
  return res.data.data;
};

export const getSummariesPeriodsV2Service = async (
  params?: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstance.get('reports/summary-periods', { params });
  return res.data.data;
};

export const getSummariesHeartRiskPeriodsV2Service = async (
  params?: DateParams,
): Promise<{ link: string }> => {
  const res = await axiosInstance.get('reports/summary-heartrisk-periods', { params });
  return res.data.data;
};

export default postGaSummariesService;
