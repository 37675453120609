import { SaveOutlined } from '@ant-design/icons';
import {
  Button, Collapse, CollapsePanelProps, DatePicker, Row, Typography
} from 'antd';
import moment from 'moment';
import React, {
  forwardRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  getReportEvaluationsService, getReportSummariesService,
  getReportSummaryEventService, getReportSummaryGropupService,
  getReportSummaryOnlyHaveQrsService, getReportSummaryTrackingQrsService
} from 'common/services/dashboard';
import { formatDateYYYYMMDD } from 'common/utils/functions';

const { RangePicker } = DatePicker;

export type SectionReportRef = {
  handleSectionSubmit: () => any | undefined;
};

export type SectionReportProps = {
  activeKey: number;
  code: string;
} & Omit<CollapsePanelProps, 'header' | 'key'>;

const dateFormat = 'YYYY-MM-DD';
const defaultDateValue = [moment(new Date()).subtract(1, 'months').format(dateFormat), moment(new Date()).format(dateFormat)];

const SectionReport = forwardRef<
  SectionReportRef, SectionReportProps
>(({
  code, activeKey, ...panelProps
}) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [dateRange, setDateRange] = useState(defaultDateValue);

  /* Functions */
  const handleSubmit = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    if (code === 'summaries' && dateRange.length === 2) {
      const res = await getReportSummariesService({ from: dateRange[0], to: dateRange[1] });
      if (res.link) {
        window.open(res.link, '_blank');
      }
    }

    if (code === 'evaluation' && dateRange.length === 2) {
      const res = await getReportEvaluationsService({ from: dateRange[0], to: dateRange[1] });
      if (res.link) {
        window.open(res.link, '_blank');
      }
    }

    if (code === 'evaluation' && dateRange.length === 2) {
      const res = await getReportEvaluationsService({ from: dateRange[0], to: dateRange[1] });
      if (res.link) {
        window.open(res.link, '_blank');
      }
    }

    if (code === 'summaryGroups' && dateRange.length === 2) {
      const res = await getReportSummaryGropupService({ from: dateRange[0], to: dateRange[1] });
      if (res.link) {
        window.open(res.link, '_blank');
      }
    }

    if (code === 'summaryEvents' && dateRange.length === 2) {
      const res = await getReportSummaryEventService({ from: dateRange[0], to: dateRange[1] });
      if (res.link) {
        window.open(res.link, '_blank');
      }
    }

    if (code === 'summaryQrs' && dateRange.length === 2) {
      const res = await getReportSummaryTrackingQrsService({
        from: dateRange[0], to: dateRange[1]
      });
      if (res.link) {
        window.open(res.link, '_blank');
      }
    }

    if (code === 'summaryOnlyHaveQrs' && dateRange.length === 2) {
      const res = await getReportSummaryOnlyHaveQrsService({
        from: dateRange[0], to: dateRange[1]
      });
      if (res.link) {
        window.open(res.link, '_blank');
      }
    }
  };

  return (
    <Collapse.Panel
      {...panelProps}
      key={activeKey}
      forceRender
      header={(
        <Typography.Title
          level={5}
        >
          {t(`reportsManagement.${code}`)}
        </Typography.Title>
      )}
      extra={(
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <SaveOutlined />
          {t('reportsManagement.buttonExport')}
        </Button>
      )}
    >
      <Row>
        <Typography.Text>{t('reportsManagement.selectRangeTime')}</Typography.Text>
        <RangePicker
          defaultValue={[moment(dateRange[0], dateFormat),
          moment(dateRange[1], dateFormat)]}
          onChange={(val) => {
            if (!val) return;
            const res = val.map((item) => (formatDateYYYYMMDD(item)));
            setDateRange(res);
          }}
          style={{ width: '100%', marginTop: '6px' }}
        />
      </Row>
    </Collapse.Panel>
  );
});

export default SectionReport;
